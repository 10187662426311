.App {
  text-align: center;
}

.bex-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* max-width: 2000px; */
  margin: 0 auto;
}

.bex-container > main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  /* min-height: 500px; */
  min-height: calc(100dvh - 73px - 55px);
  /* height: fit-content; */
  overflow-y: auto;
}

.bex-container > main > div {
  padding: 0 2rem;
  padding-bottom: 2rem;
}

.bex-container > footer, .bex-container > header {
  flex-shrink: 0;
}

/* .container > footer {
  margin-top: 2rem;
} */
